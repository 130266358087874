.dropzone {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.active {
  border-color: #007bff;
}

.app {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;
}

.version {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10px;
  color: #ccc;
  font-size: 12px;
}
